<template>
	<el-container class="home content">
		<el-aside width="200px">
			<el-menu :default-active="activeIndex" class="menu" router @open="handleOpen"
				:default-openeds="defaultOpeneds" unique-opened>
				<el-submenu class="work_bench" index="1" @click.native="openWorkbench()">
						<span slot="title">工作台</span>
				</el-submenu>
				<el-submenu index="2" title v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('Organization_Root') != -1 )">
					<template slot="title">
						<span>组织架构</span>
					</template>
					<el-menu-item index="department_management" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getDepartmentPageList') != -1 )">部门管理</el-menu-item>
					<el-menu-item index="organization_management" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getInstitutionPageList') != -1 )">机构管理</el-menu-item>
					<el-menu-item index="employee_management" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getMemberPageList') != -1 )">员工管理</el-menu-item>
				</el-submenu>
				<el-submenu index="3" title v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('Order_Root') != -1 )">
					<template slot="title">
						<span>采购订单</span>
					</template>
					<el-menu-item index="bulk_order" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getMemberOrderPageList') != -1 )">我的订单</el-menu-item>
					<el-menu-item index="order_examine" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getBulkorderexamineList') != -1 )">订单审核</el-menu-item>
					<el-menu-item index="contract_examine" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getContractExaminePageList') != -1 )">合同审核</el-menu-item>
					<el-menu-item index="contract_list" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getOrderContractPageList') != -1 )">合同列表</el-menu-item>
					<el-menu-item index="payment_list" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getOrderPayplanPageList') != -1 )">付款订单</el-menu-item>
					<el-menu-item index="delivery_order" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getShippingPlamOrderPageList') != -1 )">发货订单</el-menu-item>
					<el-menu-item index="invoice_list" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getOrderInvoicePageList') != -1 )">发票列表</el-menu-item>
					<el-menu-item index="terminate_list" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getTerminateOrderPageList') != -1 )">异常订单</el-menu-item>
					<el-menu-item index="activist" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getOrderRefundPageList') != -1 )">维权列表</el-menu-item>
				</el-submenu>
				<el-submenu index="4" title >
					<!-- v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('Deinquiry_Root') != -1 )" -->
					<template slot="title">
						<span>询价记录</span>
					</template>
					<el-menu-item index="inquiry_overview">询价概况</el-menu-item>
					<el-menu-item index="my_inquiry" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getInquiryPageList') != -1 )">询价列表</el-menu-item>
				</el-submenu>
				<el-submenu index="5" title v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('Desupplydemand_Root') != -1 )">
					<template slot="title">
						<span>供求中心</span>
					</template>
					<el-menu-item index="my_supply" v-if="member_info.papent_id == 0 || ( member_info.papent_id != 0 && member_info.institution_info && member_info.institution_info.authority.indexOf('getSupplyDemandPageList') != -1 )">供求列表</el-menu-item>
				</el-submenu>
				<el-submenu index="6" title >
					<template slot="title">
						<span>交易数据</span>
					</template>
					<el-menu-item index="order_statistics">订单统计</el-menu-item>
					<el-menu-item index="payment_statistics">付款统计</el-menu-item>
					<el-menu-item index="inquiry_statistics">询价统计</el-menu-item>
					<el-menu-item index="safeguard_rights_statistics">维权统计</el-menu-item>
					<el-menu-item index="bulk_shipping_count">发货统计</el-menu-item>
				</el-submenu>
				<el-submenu index="7" title >
					<template slot="title">
						<span>财务数据</span>
					</template>
					<el-menu-item index="order_purchase_statistics">采购统计</el-menu-item>
					<el-menu-item index="order_payment_statistics">付款统计</el-menu-item>
					<el-menu-item index="sale_after_statistics">售后统计</el-menu-item>
				</el-submenu>
				<!-- <el-submenu index="2" title>
					<template slot="title">
						<span>交易中心</span>
					</template>
					<el-menu-item index="order_list">我的订单</el-menu-item>
					<el-menu-item index="activist">退款/售后</el-menu-item>
					<template v-if="is_verify == 1">
						<el-menu-item index="verification">核销台</el-menu-item>
						<el-menu-item index="verification_list">核销记录</el-menu-item>
					</template>
				</el-submenu> -->
				<el-submenu index="8" title>
					<template slot="title">
						<span>账户中心</span>
					</template>
					<el-menu-item index="account">账户余额</el-menu-item>
					<el-menu-item index="withdrawal">提现记录</el-menu-item>
					<el-menu-item index="my_coupon">我的优惠券</el-menu-item>
					<el-menu-item index="my_point">我的积分</el-menu-item>
					<el-menu-item index="account_list">账户列表</el-menu-item>
					<!-- <el-menu-item index="level">会员等级</el-menu-item> -->
				</el-submenu>
				<el-submenu index="9" title>
					<template slot="title">
						<span>会员中心</span>
					</template>
					<el-menu-item index="index">欢迎页</el-menu-item>
					<el-menu-item index="info">个人信息</el-menu-item>
					<el-menu-item index="qizongauthentication">企宗认证</el-menu-item>
					<el-menu-item index="approve">印章管理</el-menu-item>
					<el-menu-item index="security">账户安全</el-menu-item>
					<el-menu-item index="delivery_address">收货地址</el-menu-item>
					<el-menu-item index="collection">我的关注</el-menu-item>
					<el-menu-item index="footprint">我的足迹</el-menu-item>
				</el-submenu>
			</el-menu>
		</el-aside>
		<el-main class="member">
			<transition name="slide"><router-view /></transition>
		</el-main>
	</el-container>
</template>
<script>
	import {
		checkisverifier
	} from "@/api/order/verification";
	import {
		memberInfo
	} from "@/api/member/index"
	export default {
		name: "home",
		components: {},
		data: () => {
			return {
				defaultOpeneds: ["1"],
				activeIndex: "index",
				is_verify: 1,
				member_info:{},
			}
		},
		created() {
			this.activeIndex = this.$route.meta.parentRouter || this.$route.path.replace("/member/", "")
			this.checkIsVerifier()
			this.memberInfo();
		},
		methods: {
			memberInfo() {
				memberInfo()
				.then(res => {
					if(res.code>=0){
						this.member_info = res.data;
					}
				})
				.catch(err => {
					console.log('err')
				})
			},
			openWorkbench() {
				this.$router.push('/member/workbench')
			},
			handleOpen(key, keyPath) {
				this.defaultOpeneds = keyPath
			},
			checkIsVerifier() {
				checkisverifier()
					.then(res => {
						if (res.data) {
							this.is_verify = 1
						}
					})
					.catch(err => {
						this.is_verify = 0
					})
			}
		},
		watch: {
			$route(curr) {
				this.activeIndex = curr.meta.parentRouter || this.$route.path.replace("/member/", "");
			}
		}
	}
</script>
<style lang="scss">
	.work_bench{
		.el-submenu__icon-arrow{
			display: none;
		}
	}
</style>
<style lang="scss" scoped>
	.menu {
		min-height: 730px;
		
	}

	.content {
		display: flex !important;
		margin: 20px 0;
	}

	.member {
		margin-left: 15px;
	}
</style>